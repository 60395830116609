<template>
  <div class="TagList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- MODIFY TAG -->
    <b-modal ref="modifyTagModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Modifier votre tag </strong>
      </template>
      <div>
        <b-form v-on:submit.prevent="modifyTag()">
          <b-form-group id="modify-tag-name-group" label="Nom du tag" label-for="modify-tag-name-input" class="mandatory-input">
            <b-form-input
              v-model="modifyTagName" type="text"
              maxlength="250"
              placeholder="Nom"
              id="modify-tag-username-input"
              @input="$v.modifyTagName.$touch()"
              :state="$v.modifyTagName.$dirty ? !$v.modifyTagName.$error : null">
            </b-form-input>
            <div class="form-error" v-if="$v.modifyTagName.$error">Le nom du tag doit faire entre 2 et 250 caractères</div>
          </b-form-group>

          <b-button
            pill block type="submit" variant="outline-dark" class="mt-2"
            :disabled="isInvalidModifyTag">
               Modifier votre tag
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <b-modal ref="TagUpdatedModal" id="TagUpdatedModal" size="lg" hide-footer title="Tag modifié">
      <div class="text-center">
        <p><strong>Votre tag a bien été mis à jour</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('TagUpdatedModal')">Fermer</b-button>
      </div>
    </b-modal>


    <!-- DELETE TAG -->
    <b-modal ref="deleteTagModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Supprimer ce tag </strong>
      </template>
      <div>
        <p>
          Attention, si vous supprimez cet tag, il ne sera plus possible de l'utiliser et les pages qui y sont liées ne le seront plus.
        </p>
        <b-button
          pill block variant="outline-danger" class="mt-2"
          @click='deleteTag'
          :disabled="deleteTagInProcess">
            Je confirme la suppression du tag
        </b-button>
      </div>
    </b-modal>

    <b-modal ref="TagDeletedModal" id="TagDeletedModal" size="lg" hide-footer title="Tag supprimé">
      <div class="text-center">
        <p><strong>Votre tag a bien été supprimé</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('TagDeletedModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Paramétrage des tags</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Liste des tags de Decidcrawl</strong>
              </div>
              <div class="align-self-center col-12 col-sm-6 text-right">
                <b-button variant="outline-success" pill @click="$router.push('/tags/creation')">
                  <v-icon name="plus"/> Ajouter un tag
                </b-button>
              </div>
            </div>
            <b-table
              responsive class="align-middle mt-2"
              headVariant="light"
              :fields="tagsRenderFields"
              :items="tags"
              >
              <template v-slot:cell(actions)="data">
                <b-button
                  variant="outline-primary" class="ml-2" size="sm"
                  @click='showModifyTagModal(data.item)'>
                  <v-icon name="edit"/>
                </b-button>
                <b-button
                  variant="outline-danger" class="ml-4" size="sm"
                  @click='showDeleteTagModal(data.item)'>
                  <v-icon name="trash-alt"/>
                </b-button>
              </template>
            </b-table>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { APITag } from '@/api/APITag'
import formTitleComponent from '@/components/formTitleComponent'

const apiTag = new APITag()

export default {
  name: 'TagList',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      tags: [],
      tagsRenderFields: [
        { key: "name", label: "Nom du tag", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isTagsLoading: true,

      // ----- MODIFY TAG ---------
      modifyTagInProcess: false,
      modifyTagId: '',
      modifyTagName: '',

      // ----- DELETE TAG -----
      deleteTagInProcess: false,
      deleteTagId: ''
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isTagsLoading || this.modifyTagInProcess || this.deleteTagInProcess) {
        return true
      }
      return false
    },
    isInvalidModifyTag () {
      if (this.$v.modifyTagName.$invalid) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyTagName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
  },
  created: function() {
    this.getAllTags()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllTags () {
      this.isTagsLoading = true
      apiTag.getAllTags(this.token)
      .then((result) => {
        this.tags = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagList getAllTags API Error : ' + String(error))
      })
      .finally(() => {
        this.isTagsLoading = false
      })
    },

    // ----------- MODIFY REQUETE -----
    showModifyTagModal (tag) {
      this.modifyTagId = tag.id
      this.modifyTagName = tag.name
      this.$refs.modifyTagModal.show()
    },

    modifyTag () {
      this.modifyTagInProcess = true
      apiTag.modifyTag(
        this.token, this.modifyTagId, this.modifyTagName)
      .then(() => {
        this.$refs.modifyTagModal.hide()
        this.$refs.TagUpdatedModal.show()
        this.getAllTags()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagList modifyTag API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyTagInProcess = false
      })
    },


    // ----------- DELETE REQUETE -----
    showDeleteTagModal (tag) {
      this.deleteTagId = tag.id
      this.$refs.deleteTagModal.show()
    },

    deleteTag () {
      this.deleteTagInProcess = true
      apiTag.deleteTag(this.token, this.deleteTagId)
      .then(() => {
        this.$refs.deleteTagModal.hide()
        this.$refs.TagDeletedModal.show()
        this.getAllTags()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagList deleteTag API Error : ' + String(error))
      })
      .finally(() => {
        this.deleteTagInProcess = false
      })

    },
  }
}
</script>
